.projects-content {
  margin: 0 20vh;
  padding-top: 10vh;
}

.projects-content > span {
  font-size: 3vh;
  font-weight: 600;
}

.projects-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.projects-card {
  width: 40vh;
  height: 15vh;
  margin: 3vh;
  border: 0.01vh solid #3a3a3a;
  background-color: #202020;
  border-radius: 0.5vh;
}

.projects-see-more {
  width: 100%;
  height: 3vh;
  margin: 3vh 13vh;
  border: 0.01vh solid #3a3a3a;
  background-color: #202020;
  border-radius: 0.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

.projects-header {
  margin: 3vh 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projects-header a {
  color: #0567df;
  text-decoration: none;
  font-size: 2vh;
}

.projects-description {
  margin: 3vh 2vh;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .projects-content {
    margin: 0;
  }

  .projects-content > span {
    margin-left: 3vh;
  }

  .projects-card {
    margin: 1.5vh;
  }

  .projects-see-more {
    margin: 1.5vh 3vh 3vh;
  }
}