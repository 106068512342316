.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 5vh;
  padding: 0 1vh;
  background-color: #00000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: 0.3s background-color ease-in-out;
  animation: fadeInDown 800ms ease-in-out normal;
}

@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-5vh);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
} 

.navbar-hello, .navbar-sections, .navbar-social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-menuLink {
  text-decoration: none;
  margin: 0 0.4vh;
  color: #c4c4c4;
  transition: 0.1s color ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}

.navbar-menuLink:hover {
  cursor: pointer;
  color: white;
  transition: 0.1s color ease-in-out;
}

.navbar-wordart {
  height: 2.3vh;
}

.navbar-menuButton {
  border: none;
  color: #c4c4c4;
  background-color: transparent;
  transition: 0.1s color ease-in-out;
  font-size: 1.4vh;
  margin: 0 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
}

.navbar-menuButton:hover {
  cursor: pointer;
  color: white;
  transition: 0.1s color ease-in-out;
}

.navbar-contact {
  border: 0.2vh solid rgb(5,103,223);
  border: 0.2vh solid linear-gradient(180deg, rgba(5,103,223,1) 0%, rgba(30,146,255,1) 50%, rgba(5,103,223,1) 100%);
  font-weight: 600;
  border-radius: 0.5vh;
  padding: 0.5vh 1vh;
  width: 7vh;
  height: 1.6vh;
  font-size: 1.3vh;
}

.navbar-contact span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-active {
  color: white;
}

.navbar-contact:hover {
  background-color: #0000002a;
}

.navbar-contact * {
  margin: 0 0.15vh;
}

.navbar-socials {
  width: 2.2vh !important;
  height: 2.2vh !important;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .navbar {
    height: 80px;
  }

  .navbar-sections {
    display: none;
  }

  .navbar-hello {
    margin: 0;
    margin-left: 1vh;
  }

  .navbar-menuLink {
    margin: 0 0.8vh;
  }

  .navbar-socials {
    width: 3.5vh !important;
    height: 3.5vh !important;
  }

  .navbar-wordart {
    height: 3vh;
  }

  .navbar-contact {
    display: none;
  }
}