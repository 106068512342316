.hello {
  margin-top: 5vh;
  height: calc(100vh - 5vh);
}

.hello-bg {
  background-color: #1b1b1b;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.hello-content {
  position: absolute;
  top: 40vh;
  left: 20vh;
  right: 80vh;
  width: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hello-content > div {
  margin: 1vh 1.5vh;
}

.hello-greeting {
  font-size: 4vh;
}

.hello-bio {
  font-size: 2vh;
  text-align: justify;
}

.hello-scroll-down {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hello-expander {
  width: 3vh !important;
  height: 3vh !important;
}

.hello-expander:hover {
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hello {
    margin-top: 80px;
    height: calc(100vh - 80px);
  }

  .hello-content {
    box-sizing: border-box;
    position: static;
    margin: 0;
    padding: 5vh 3vh 3vh;
    width: 100%;
  }
}