.info-content {
  margin: 0 20vh;
  padding-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-content > * {
  margin: 3vh;
}

.info-skyline {
  width: 100%;
  min-height: 30vh;
  height: auto;
}

.info-skyline > * {
  min-height: 30vh !important;
}

.info-technologies {
  width: 100%;
  min-height: 30vh;
  height: auto;
}

.info-title {
  font-size: 3vh;
  font-weight: 600;
}

.info-technologies-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3vh 20vh;
}

.info-technologies-icon {
  width: 8vh;
  height: 8vh;
  margin: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-technologies-svg {
  height: 8vh;
  width: 8vh;
  object-fit: contain;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .info-content {
    margin: 0 3vh;
  }
  
  .info-technologies {
    margin: 0;
  }
  
  .info-technologies-icons {
    margin: 0; 
  }
}