.hobbies-content {
  margin: 0 20vh;
  padding-top: 10vh;
}

.hobbies-content > span {
  font-size: 3vh;
  font-weight: 600;
}

.hobbies-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.hobbies-card {
  width: 40vh;
  height: 25vh;
  margin: 3vh;
  background-color: #202020;
  border: 0.01vh solid #3a3a3a;
  border-radius: 0.5vh;
}

.hobbies-img {
  width: 100%;
  height: 80%;
}

.hobbies-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hobbies-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20%;
  padding-left: 2vh;
}

.hobbies-header a {
  text-decoration: none;
  color: #0567df;
  font-size: 2vh;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hobbies-content {
    margin: 0;
  }

  .hobbies-content > span {
    margin-left: 3vh;
  }

  .hobbies-card {
    margin: 1.5vh 3vh 1.5vh;
  }
}