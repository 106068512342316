.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 5vh;
  font-size: 1.3vh;
  font-weight: 600;
  color: #6d6d6d;
}
.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-email {
  color: #6d6d6d;
  text-decoration: none;
}

.footer-email:hover {
  text-decoration: underline;
  color: white;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .footer {
    height: 7vh;
    font-size: 1.5vh;
    margin-bottom: 4vh;
  }
}